import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from '../../pages/login/styles.scss';
import { emailValidation } from '../../helpers/validators';

const propTypes = {
};

const messages = defineMessages({
  nameTitle: {
    id: 'login.nameTitle',
    defaultMessage: 'Email Address',
    description: 'Username Input Label',
  },
  passwordTitle: {
    id: 'login.passwordTitle',
    defaultMessage: 'Password',
    description: 'Password Input Label',
  },
  submitText: {
    id: 'login.submitText',
    defaultMessage: 'Submit',
    description: 'Submit button text in login form',
  },
  invalidEmail: {
    id: 'login.invalidEmail',
    defaultMessage: 'Please enter a valid email',
    description: 'Invalid email message on login',
  },
  placeholderPassword: {
    id: 'login.placeholderPassword',
    defaultMessage: 'password',
    description: 'Placeholder password on login page',
  },
  loginForgotPassword: {
    id: 'login.ForgotPassword',
    defaultMessage: 'Forgot password?',
    description: 'The text of the forgot password link',
  },
  showPassword: {
    id: 'login.ShowPassword',
    defaultMessage: 'Show Password',
    description: 'The text of the show password checkbox label',
  },
});

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState('password');

  const handleSubmit = () => (
    setSubmitting(true)
  );

  const passwordInputTypeToggle = () => (
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')
  );

  const validateEmail = () => (
    setEmailError(!emailValidation(email))
  );

  const handleEmailChange = event => (
    setEmail(event.target.value)
  );

  useEffect(() => {
    if (email) {
      validateEmail();
    }
  }, [email]);

  return (
    <Form onSubmit={handleSubmit} method="post" action="/login">
      <FormGroup className={styles.formGroup}>
        <Label for="usernameOrEmail" className={`${styles.label} h5`}>
          <FormattedMessage {...messages.nameTitle} />
        </Label>
        <Input
          className={`${styles.input} ${emailError && styles.input_error}`}
          id="usernameOrEmail"
          type="email"
          name="username"
          value={email}
          onChange={handleEmailChange}
          invalid={!!emailError}
          required
        />
        { emailError &&
          <div
            className={`${styles.emailError} mt-1`}
            role="alert"
          >
            <FormattedMessage {...messages.invalidEmail} />
          </div>
        }
      </FormGroup>
      <FormGroup>
        <Label className={`${styles.label} h5`} htmlFor="password">
          <FormText />
          <FormattedMessage {...messages.passwordTitle} />
          <a href="/forgot-password" className={styles.forgotPassword}>
            <FormattedMessage {...messages.loginForgotPassword} />
          </a>
        </Label>
        <Input
          id="password"
          type={passwordInputType}
          name="password"
          className={`mb-2 ${styles.input}`}
        />
        <Label type="button" className={styles.showPasswordContainer} for="showPassword">
          <input type="checkbox" id="showPassword" onChange={passwordInputTypeToggle} className="mr-2" />
          <span>
            <FormattedMessage {...messages.showPassword} />
          </span>
        </Label>
      </FormGroup>
      <Button type="submit" block className={styles.buttonSubmit} disabled={submitting}>
        <FormattedMessage {...messages.submitText} />
      </Button>
    </Form>
  );
};

LoginForm.propTypes = propTypes;

export default injectIntl(withStyles(styles)(LoginForm));
