import React from 'react';
import { defineMessages } from 'react-intl';
import Layout from '../../components/Layout/Layout';
import Login from './Login';

const messages = defineMessages({
  titleLogIn: {
    id: 'title.Login',
    defaultMessage: 'Login',
    description: 'Log in page title',
  },
});

function action({ intl }) {
  const title = intl.formatMessage(messages.titleLogIn);
  return {
    chunks: ['login'],
    title,
    component: (
      <Layout>
        <Login title={title} />
      </Layout>
    ),
  };
}

export default action;
