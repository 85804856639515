import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FormGroup, Button, Container, Row, Col } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withCookies, Cookies } from 'react-cookie';
import queryString from 'query-string';
import { alert } from '../../actions/alerts';
import {
  getCurrentLocale,
  getUser,
} from '../../selectors';
import Link from '../../components/Link/Link';
import styles from './styles.scss';
import LoginForm from '../../components/LoginForm';
import AlertBadge from '../../components/Alerts/AlertBadge';

const messages = defineMessages({
  toMainPage: {
    id: 'login.mainPageText',
    defaultMessage: 'Go to main page',
    description: 'Go to Main page button',
  },
  accountHiMessage: {
    id: 'layout.accountHiMessage',
    defaultMessage: 'Welcome Back,',
    description: 'ACCOUNT welcome message',
  },
  loginHeader: {
    id: 'login.Header',
    defaultMessage: 'Sign in',
    description: 'The main header on the login page',
  },
  loginSystemUpdate: {
    id: 'login.SystemUpdated',
    defaultMessage: 'The system has been updated. Please login again to continue.',
    description: 'A message indicating the application has updated',
  },
});

const Login = ({ title, cookies, user }) => {
  const [systemUpdated, setSystemUpdated] = useState(false);

  useEffect(() => {
    setSystemUpdated(!!queryString.parse(window.location.search).system);
  }, []);

  return (
    <main className={styles.root}>
      { user ?
        <Container>
          <Row>
            <Col xs={12}>
              <FormattedMessage {...messages.accountHiMessage} />
              {` ${user.first_name} ${user.last_name}!`}
            </Col>
            <Col xs={12}>
              <Button to="/" tag={Link}>
                <FormattedMessage {...messages.toMainPage} />
              </Button>
            </Col>
          </Row>
        </Container>
        :
        <FormGroup className={styles.container}>
          <h1 className={`${styles.loginHeader} text-center`}>
            <FormattedMessage {...messages.loginHeader} />
          </h1>
          {systemUpdated ? (
            <AlertBadge hideClose>
              <FormattedMessage {...messages.loginSystemUpdate} />
            </AlertBadge>
          ) : null}
          <LoginForm />
        </FormGroup>
      }
    </main>
  );
};

const mapState = state => ({
  user: getUser(state),
  currentLocale: getCurrentLocale(state),
});

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ alert }, dispatch),
});

export default injectIntl(connect(mapState, mapDispatch)(withCookies((withStyles(styles)(Login)))));
