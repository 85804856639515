import Payment from 'creditcardutils';

export const noWhiteSpaceValidation = value => (/^\s*$/.test(value));
export const emailValidation = value => (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value));
export const intlPhoneValidation = value => (/^[+][\d\s]+/.test(value));
export const phoneNumberValidation = value => (/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}/.test(value));
export const cardLengthValidation = (number) => {
  const cardNumber = number.replace(/ /ig, '');
  if (!cardNumber || cardNumber.length < 15) {
    return 'Card number should be 15 or 16 characters.';
  } else if (!Payment.validateCardNumber(cardNumber)) {
    return 'Please type correct card number.';
  }
  return false;
};
